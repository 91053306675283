<template>
	<div class="pageContainer">
		<web-header></web-header>
		<div class="content">
			<router-title :menuList="menuList[0]" :list="menuList[1]" />
			<div class="detalTop">
				<div class="cover">
					<img :src="details.surfacePlot" alt="">
				</div>
				<div class="mesg">
						<div class="name">{{details.name}}</div>
					<div class="teacher">主讲教师：{{details.teacherName}}</div>
					<div class="people_num" >预约人数：{{details.bookedPeople}}人</div>
						<div class="time">直播时间：{{details.beginTime}} 到 {{details.endTime}}</div>
							<div class="btn_group">
						<div class="btn reserve_btn" v-if="!details.stuToLive" @click="handleReservationNow">
							<img src="../../assets/live/icon_no_booked.png" alt="">
							<span>立即预约</span>
						</div>
						<div class="btn" v-if="details.stuToLive && details.status == 0">
							<img src="../../assets/live/icon_booked.png" alt="">
							<span>未开始</span>
						</div>
						<div class="btn reserve_btn" v-if="details.stuToLive && details.status == 1"
							@click="handleWatchLive">
							<img src="../../assets/live/liveNow.png" alt="">
							<span>正在直播</span>
						</div>
						<div class="btn" v-if="details.stuToLive && details.status == 2">
							<img src="../../assets/live/icon_finished.png" alt="">
							<span>已结束</span>
						</div>
					</div>
				</div>
			</div>
			<div class="detalButton">
				<div class="detalHeader">培训介绍</div>
				<div class="detaljies">{{details.introduce}}</div>
			</div>
		</div>
		<web-footer></web-footer>
	</div>
</template>

<script>
	import WebFooter from '../../components/webFooter.vue'
	import webHeader from '../../components/webHeader.vue'
	import RouterTitle from "../../components/routerTitle.vue";
	import {
		mapState
	} from 'vuex';
	export default {
		components: {
			webHeader,
			WebFooter,
			RouterTitle
		},
		data() {
			return {
				menuList: ["首页", "直播课堂"],
				status: 1,
				details: {},
				loading: ''
			}
		},
		created() {
			this.getLiveInfo()
			// //回看详情
			// if (this.$route.query.status === '1') {
			// 	this.status = 1
			// 	this.item = this.$route.query.it
			// } else {
			// 	this.status = 2
			// 	this.item = this.$route.query.it
			// 	this.id = localStorage.getItem('cousreId')
			// 	this.getLiveInfo(this.id)
			// }
		},
		computed: {
			...mapState(['isLogin'])
		},
		methods: {
			// 获取直播的详细信息
			getLiveInfo() {
					this.loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
				this.$http.post('liveById', {
					id: this.$route.query.id
				}).then(res => {
					if (res.code === 200) {
						this.details = res.data
						console.log(this.details, 'details');
						this.loading.close()
					} else {
						this.$message.error(res.message)
						this.loading.close()
						
					}
				})
			},
			// 立即预约
			handleReservationNow() {
				// 保存id，防止刷新
				localStorage.setItem('cousreId', this.details.id)
				if (!this.isLogin) return this.$message.error('请先登录')
				this.$router.push({
					path: '/trainingProgram/paymentOrder',
					query: {
						index: 4,
						type: 1,
						data: this.details
					}
				})
			},
			// 观看直播
			handleWatchLive() {
				this.$router.push({
					path: '/liveInfo',
					query: {
						id: this.details.id
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.content {
		width: 1200px;
		margin: auto;
	}
	.detalTop{
		display: flex;
		padding: 40px 52px;
		min-height: 316px;
		background: #FFFFFF;
		box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.09);
		border-radius: 20px;
		margin-bottom: 20px;
		.cover {
			width: 343px;
			height: 238px;
			margin-right: 64px;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.mesg{
			width: 660px;
			.name{
				font-size: 24px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #333333;
				margin-bottom: 32px;
			}
			.teacher,.people_num,.time{
				font-size: 16px;
				font-family: PingFang SC;
				font-weight: 400;
				color: #666666;
				margin-bottom: 30px;
			}
			.btn{
					float: right;
				text-align: center;
				line-height: 53px;
				width: 151px;
				height: 53px;
				background: #39AEFF;
				border-radius: 27px;
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #FFFFFF;
				cursor: pointer;
				img {
					width: 16px;
					height: 16px;
					margin-right: 11px;
				}
		}
		}
		
	}
	.detalButton{
		padding: 32px 50px;
		min-height: 335px;
		background: #FFFFFF;
		box-shadow: 0px 3px 24px 0px rgba(0, 0, 0, 0.09);
		.detalHeader{
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: bold;
			color: #39AEFF;
			margin-bottom: 32px;
		}
		.detaljies{
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #333333;
			 text-overflow: -o-ellipsis-lastline;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 7;
				line-clamp: 7;
				-webkit-box-orient: vertical;
		}
	}

</style>
